import React, {useState, useCallback, useEffect} from "react";
import './Lightbox.css';

const Lightbox = (props) => {

    const [display_lightbox, toggleLightBoxDisplayed] = useState(false);
    const [displayed_image, setDisplayedImage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const handleClick = () => {
        toggleLightBoxDisplayed(!display_lightbox);
    }
    const handleBack = (e) => {
        e.stopPropagation();
        setDisplayedImage(displayed_image - 1 < 0 ? props.src.length - 1 : displayed_image - 1);
    }
    const handleForward = (e) => {
        e.stopPropagation();
        setDisplayedImage(displayed_image + 1 === props.src.length ? 0 : displayed_image + 1);
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            toggleLightBoxDisplayed(false);
        }
      }, []);

    /* Keep track of screen size (to decide if we can show lightboxes */ 
    /*
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        if(screenSize.width < 400)
            toggleLightBoxDisplayed(false);
    }, [screenSize]);
*/
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const images = props.src.map((src, i) => {
        const test = new Image();
        test.src = src;
        test.onload = () => {
            //console.log('loaded ' + i + ' ' + JSON.stringify(src))
        }
        return <img key={src} src={src} alt={props.alt} className='lightbox_image' onLoad={(e)=>{setLoading(false)}} style={{display: !loading && src === props.src[displayed_image] ? "block":"none"}} />
    });
    
    const lightbox = (
        <div className="lightbox_container" onClick={handleClick} >
                <div className='lightbox'>
                    <button className='lightbox_button' onClick={handleBack} disabled={props.src.length <= 1} style={{'visibility': props.src.length <= 1 ? 'hidden' : 'visible'}}>

                        <span className="material-symbols-outlined lightbox-icon">
                            arrow_back
                        </span>
                    </button>
                    <div  style={{display: loading ? "block" : "none"}}>Loading...</div>
                        {images}
                    <button className='lightbox_button' disabled={props.src.length <= 1} style={{'visibility': props.src.length <= 1 ? 'hidden' : 'visible'}}>
                        <span className="material-symbols-outlined lightbox-icon" onClick={handleForward}>
                            arrow_forward
                        </span>
                    </button>
                    <button className='lightbox_button close' >
                        <span className="material-symbols-outlined lightbox-icon" onClick={handleClick}>
                            close
                        </span>
                    </button>
                </div>
            </div>
        )
    const image_shadow = props.no_shadow ? '' : 'image-shadow';
    return(
        <>
            {display_lightbox ? lightbox : ''}
            <img src={props.src[0]} alt={props.alt} className={'image ' + image_shadow + ' lightbox_thumbnail'} onClick={handleClick} />
        </>
    );
}

export default Lightbox;