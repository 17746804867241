
import './Logo.css';
export  const Logo = ({width}) => {
  return (
  <svg className='logo' width="1130" height="421" viewBox="0 0 1130 421" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse className='dot1' cx="148.148" cy="147.715" fill="#F23A11" rx="112.795" ry="112.715"/>
    <ellipse className='dot2' cx="604.232" cy="272.682" fill="#F23A11" rx="112.795" ry="112.715"/>
    <ellipse className='dot3' cx="981.85" cy="272.682" fill="#F23A11" rx="112.795" ry="112.715"/>
    <ellipse className='dot4' cx="795.493" cy="88.908" fill="#F23A11" rx="46.589" ry="46.556"/>
    <path fill="#F23A11" d="M334.506 42.35h88.274v338.145h-88.274z"/>
  </svg>
  )
}  