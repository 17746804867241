import React, {useEffect, useState} from "react";

import pluie from './Images/pluie-text.svg';

import aircela_exploration_1 from './Images/Style exploration 1.png';
import aircela_exploration_2 from './Images/Style exploration 2.png';
import aircela_ipad from './Images/Portfolio Aircela iPad.png';
import aircela_moodboard from './Images/Moodboard.png';
import aircela_layouts from './Images/Layout variants.png';
import aircela_concept from './Images/Aircela visual concept.png';

import orc from './Images/Impact - OrganicRankineCycle - experiment 2x.png';
import ev from './Images/Impact - EV - result 2x.png';
import JW from './Images/JW.png';
import MF from './Images/MF.png';
import details2 from './Images/Details panel 4x.png';
import vis_id2 from './Images/Visual identity 4x.png';
import modals2 from './Images/Modals 3x.png';
import wsc1 from './Images/WSC - Overview.png';
import wsc2 from './Images/WSC - Overview with dropdowns.png';
import wsc3 from './Images/WSC - Edit workspace with packages.png';
import martin from './Images/Martin.jpg';
import {Logo} from './Logo';
import Lightbox from './Lightbox'
import './App.css';

function App() {  

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      }

      window.addEventListener("scroll", handleScroll);
      
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollPosition])

  const cacheImages = async (srcArray) => {

    const promises = await srcArray.map((src) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        
        img.onload = function() {
          resolve(src);
        }
        img.onerror = function() {reject('Could not load' + src);}
        img.src = src;
      });
    });
    //console.log(promises)
    
    const all_image_promises = Promise.all(promises);

    all_image_promises.then((vals) => {
      //console.log(vals);
    }).catch(error => {
      console.log(error); // rejectReason of any first rejected promise
    });
    
    //Promise.all(promises).then(console.log('done'));
    
  };

   useEffect(() => {
    const imgs = [
      orc,
      ev,
      JW,
      MF,
      details2,
      vis_id2,
      modals2,
      wsc1,
      wsc2,
      wsc3,
      martin
    ];
    /*cacheImages(['./Images/pluie-text.svg']).then(cacheImages([ev])).then(cacheImages([vis_id2])).then(cacheImages([wsc1])).then(cacheImages(imgs));*/
    cacheImages(imgs);
  }, []);

  return (
    <div className="app">
      <header className="header">
        <div className='logo-stuff'>
          
          <Logo className='logo' alt="logo" />
          <img src={pluie} className='pluie-header-text' alt="logo" />
        </div>
        <div className='intro-text copy-header'>
          <p>
            Pluie is a small digital design studio specialized in design of web applications in the intersection of art and science. Applications we work with are often complex with plenty of data and interaction involved.
          </p>
          <p>We love making complex things easy and delightful.</p>
        </div>
        <div className='float-footer copy-header'>
          <div className='work-with-me'>Say hello 
            <span className="material-symbols-outlined right-arrow">
              arrow_forward
            </span>
          </div>
          <a href='mailto:hej@pluie.se' className='email'>hej@pluie.se</a>
        </div>
      </header>
      <div className='work'>
        <div className='header2 h2'>
          SELECTED WORK
        </div>
        <div className='middle-container copy'>
        <div className='project'>
            <div className='header3 h3'>
              CO2 Direct Air Capture Demo
            </div>
            <div className='project-info'>
              <div className='project-info-text copy'>Visual design & development</div>
              <div className='separator-dot' />
              <div className='project-info-text copy'>Aircela</div>
              <div className='separator-dot' />
              <div className='project-info-text copy'>2023</div>
            </div>
            <p>
              Aircela is a New York City based innovation company that makes small-scale systems that produces carbon neutral e-fuels from carbon dioxide in the air.
            </p>
            <p>
              The first part of the project was about designing a single page visualization display that showcases part of their technology, the direct air capture of carbon dioxide. The display, being either a large screen or tablet, was to be used at a Maersk event celebrating the worlds first container vessel sailing on green methanol. The screen should display live data from two sensors showing live how CO2 is extracted by the system from the air. The task was to design the screen and implement the visual part of it. Aircela took care of the hardware and backend part themselves.
             </p>
            <p>
              The second part of the project consisted of creating a visual user interface concept for a control panel mockup of the Aircela system. This was to be used with a full size physical mockup of the system at the same event as the direct air capture visualization.
            </p>
            <p>
              The project was carried out remotely between Sweden and NYC.
            </p>
            <div className='header4 h4'>
              Responsibilities
            </div>
            <p>
              <ul>
                <li>Visual design</li>
                <li>Web technology choices</li>
                <li>Frontend code development</li>
              </ul>
            </p>
            <div className='header4 h4'>
              Process
            </div>
            <p>
              We had an introductory meeting where Aircela explained their expectations and ideas and shared relevant company branding assets.
            </p>
            <p>
              Based on the input, we came up with a set of layout designs suggestions that we then reviewed together to decide what was most suitable with regards to audience, system properties and the message to be conveyed. We quickly narrowed down a design with two seismograph-like graphs and an existing 3D infographics animation.
            </p>
            <div>
              <Lightbox src={[aircela_layouts]} alt='Layout choices for the CO2 direct air capture visualization' />
            </div>
            <p>
              We then proceeded to make the detailed screen mockup design and iterated with Aircela until we were happy.
            </p>
            <p>
              In parallel we made some technology proof of concepts setting up sensors in the Aircela NYC office that communicated with frontend code in Malmö, Sweden to make sure the technical choices were feasible and robust enough.
            </p>
            <p>
              When the technical side was validated, we proceeded to implement the frontend based on the high fidelity mockups. To enable Aircela to follow the progress in realtime, we set up a sandbox prototype cloud environment where they could always see the latest state of the visualization screen frontend code. In this way, Aircela could provide feedback continuously and asynchronously, allowing faster progress.
            </p>
            <p>
              As we converged on the implementation, Aircela got access to the code and installed it on the hardware system to proceed with doing dry-runs. Everything worked as expected and only minor visual tweaks were done the day before the event when the visualization display was tested on the production tablet.
            </p>
            <div>
              <Lightbox src={[aircela_ipad]} alt='The CO2 direct air capture demo visualization running on an ipad' />
            </div>
            <div className='header4 h4'>
              Visual user interface concept
            </div>
            <p>
              The brief for the user interface concept emphasized that the interface should be human-centered and simple for anyone to use. Style-wise it should adhere to Aircela branding, use hexagon shapes and have an elegant e-ink feel to it. We went ahead and started exploring visual possibilities for the UI.
            </p>
            <div>
              <Lightbox src={[aircela_exploration_1, aircela_exploration_2, aircela_moodboard]} alt='Visual style exploration' />
            </div>
            <p>
              This part of the project progressed steadily with a number of design reviews until Aircela was happy with the design. The concept was then handed over to Aircela who proceeded to implement it for the system.
            </p>
            <div>
              <Lightbox src={[aircela_concept]} alt='Aircela system prototype UI concept' no_shadow={true}/>
            </div>

            <div className='project-separator' />
          </div>
          <div className='project'>
            <div className='header3 h3'>
              Modelon Impact
            </div>
            <div className='project-info'>
              <div className='project-info-text copy'>UI / UX design</div>
              <div className='separator-dot' />
              <div className='project-info-text copy'>Modelon</div>
              <div className='separator-dot' />
              <div className='project-info-text copy'>2016 - 2022</div>
            </div>
            <p>
              Impact is simulation-specialist-turned-product-company Modelon's flagship simulation platform. The web-based platform acts both as a model editing environment, an execution platform and an analysis tool.
            </p>
            <div>
              <Lightbox src={[ev, orc]} alt='Mockups of Modelon Impact' />
            </div>
            <p>
              The assignment was to design the full user interface of the platform from scratch, making sure that usability is at the core of the application. Simulation platforms are generally complex to use and users need significant training. Usability and user experience was thus a priority to make Modelon Impact a next generation simulation tool. During the 4.5 years of the assignment, the work included:
            </p>
            <div className='header4 h4'>
              Strategic UX work
            </div>
            <p>
              We worked closely with the Product Management team, contributing to the product definition and creating wireframes and mockups. These assets were used for strategic discussions with stakeholders and design iterations with the development teams.
            </p>
            <div className='quote'>
              <img src={JW} className='profile-image' alt='Portrait of Johan Windahl' />
              <div className='quote-text'>
                <div className='copy-quote'>
                “Martin's UX expertise combined with a background in engineering made him well-equipped to tackle and solve our complex design problems”
                </div>
                <div className='quote-name'>
                  - Johan Windahl<span className='the-title'>, Product Manager Modelon Impact</span>
                </div>
              </div>
            </div>

            

            <div className='header4 h4'>
              UI / UX design
            </div>
            <p>
              Pluie was responsible for the overall user experience of the application, ensuring complex engineering workflows were intuitive and straightforward. The work was done in a SCRUM environment and we were involved in both early phase conceptual explorations as well as in creating the final design for user stories, from design principles, personas and high level application structure to nitty gritty details of specific parts of the application. We continuously participated in and supported design discussions with different stakeholders ensuring a consistent and top notch user experience.
            </p>
            <p>
              As the application and team grew, a design system was created in Figma to support the organization going forward.
            </p>
            <div>
              <Lightbox src={[vis_id2, details2, modals2]} className="image" alt='Parts of the Modelon Impact design system' style={{'filter': 'none'}} />
            </div>



            <div className='header4 h4'>
              Day to day interaction with developers 
            </div>
            <p>
              Continuous daily interaction with the frontend developers was a substantial part of the work we did with Modelon Impact. Tasks ranged from producing wireframes for early stage design discussions to making pixel-perfect mockups for development stories and working shoulder-to-shoulder in dev tools to help nail down the implementation. Always being available for discussions and support was highly appreciated among the development team.
            </p>
            <div className='quote'>
              <img src={MF} className='profile-image' alt='Portrait of Magnus Ferm' />
              <div className='quote-text'>
                <div className='copy-quote'>
                “Martin was very easy to work with and was always open to suggestions from the team (within reason). I'm hoping to get to work with him again in the future, and I can wholeheartedly recommend him.”
                </div>
                <div className='quote-name'>
                  - Magnus Ferm<span className='the-title'>, Full-stack developer at tretton37</span>
                </div>
              </div>
            </div>



            <div className='header4 h4'>
              User feedback and testing
            </div>
            <p>
              In order to make a leap in terms of user experience, creating the next generation simulation and modeling platform, users were involved from the start throughout the project. Several early adopter companies were closely involved in the design and implementation process and we handled most of this communication. We helped out with interviews, workshops, evaluation of prototypes and user testing.
            </p>

            <div className='project-separator' />
          </div>
          <div className='project'>
            <div className='header3 h3'>
              Workspace Management App
            </div>
            <div className='project-info'>
              <div className='project-info-text'>UI / UX design</div>
              <div className='separator-dot' />
              <div className='project-info-text'>Modelon</div>
              <div className='separator-dot' />
              <div className='project-info-text'>2021 - 2022</div>
            </div>
            <p>
              The goal of this project was to design and implement an app that would allow Impact users to handle versioning of Modelica libraries that are used within the platform.  The project was on a tight schedule before the end of year release.
            </p>
            <p>
            We were responsible for the UI / UX design of the application. The main challenge was to apply the computer science-oriented versioning concept to Modelica libraries where users are not necessarily proficient using such systems. Versioning systems can be (very) complex, so identifying relevant workflows to be able to streamline the experience as much as possible with the user goals in mind was crucial.
            </p>
            <p>
              The workspace management app was delivered on time and was shortly after in the hands of paying customers.
            </p>
            <div>
              <Lightbox src={[wsc1, wsc2, wsc3]} className="image" alt='Modelon Impact workspace management app' />
            </div>
            <div className='header4 h4'>
              UX Design
            </div>
            <p>
              We arranged a series of user interviews to get a thorough understanding of user goals and expectations of versioning of Modelica libraries. After analysis and ideation, lo-fidelity mockups were produced to collect feedback and validate the design. Development cycles were short and a continuous integration environment enabled users to test the app as it was coming together to give early feedback.
            </p>
            <div className='header4 h4'>
              UI Design
            </div>
            <p>
            The UI design was made to feel like an extension of the platform but still playing with the style of the platform as it comes bundled with it. The goal was to make the app very straightforward and focus on the Impact library workflows rather than the versioning technology behind it.
            </p>              
          </div>
        </div>
      </div>
      <div className='about'>
        <div className='header2 h2 light'>
          ABOUT
        </div>
        <div className='middle-container copy'>
              <p className='no-margin'>
                <p className='about-p'> 
                  Pluie is a digital design studio owned and run by Martin Malmheden. It was created to explore usability at the intersection of art and technology. We believe that mixing things up and injecting inspiration from the side opens up for new and interesting experiences.
                </p>
                <p className='about-p'>
                <img src={martin} className='image-martin' alt="Martin" />
                We help companies handle complexity in product design and are passionate about making sure it’s enjoyable for users. We have long experience in the engineering simulation software space, a complex and data-heavy environment, but we love sinking our teeth into any kind of project where visionary use of technology is put into practice. Recently we’ve worked with clients in domains like fintech, self-storage, green tech, etc.
                </p>
                <div className='header4 h4'>
                  Services
                </div>
                <p>
                We provide hands-on expertise in all parts of the process of designing an application. Regardless if you're working with a small experimental project or building a complex platform, we’ll help making sure your users will have a straightforward and delightful experience using it. To smoothen the transition between design and development we also offer prototype frontend web-development or alternatively we’ll work in tight collaboration with your developers if that suits your project better. We love shoulder-to-shoulder css tweaking in dev tools.
                </p>
                <p>
                  <ul>
                    <li>User research</li>
                    <li>Interaction / UX design</li>
                    <li>Visual / UI design</li>
                    <li>User testing</li>
                    <li>Clickable prototypes</li>
                    <li>Code prototypes (JS/Node/React/D3)</li>
                  </ul>
                </p>
                <p className='about-p'>
                  There’s a special place in our hearts, and priority list, for projects that have a positive impact on the environment
                  <span className="material-symbols-outlined heart">
                    favorite
                  </span>
                </p>
              </p>
        </div>
      </div>
    </div>
  );
}
export default App;